import {get, post, remove} from '@/lib/axios';

export default {
    async addItem(productId, quantity = 1, size = null) {
        return post(`/cart/product/${productId}`, {quantity, size});
    },
    async removeItem(productId, quantity = 1,  completely = false, size = null) {
        return remove(`/cart/product/${productId}`, {
            params: {
                completely: +completely,
                quantity,
                size
            }
        });
    },
    async getCart() {
        return get('/cart/info');
    },
    async getDeliveryAddresses() {
        return get('/cart/delivery-addresses');
    },
    async getSubdivisionUserInfo() {
      return get('/cart/subdivision-user-info');
    },
    async createOrder(data) {
        return post(`/cart/order`, data);
    }
}
