import {setLoading, removeLoading} from "@/lib/loading";
import api from "@/api";
import {showMessage} from "@/lib/toasted";
import {showConfirm} from "@/lib/swal2";
import {DeliveryToOtherRegion, DeliveryToMoscow, DeliveryToTheOffice} from "@/config/subdivision_delivery_types";
import router from "@/router";

export default {
    namespaced: true,
    state: {
        isCartLoaded: false,
        isCartUpdating: false,
        isProductOrderCreating: false,
        areSameAgenciesProducts: null,
        deliveryInfo: {
            type: null,
            region: null,
            city: null,
            address: null,
            street: null,
            house: null,
            recipientName: null,
            recipientEmail: null,
            contactNumber: null,
            optionalContactNumber: null,
            orderPurpose: null,
            orderOrganization: null,
            comment: null,
            splitOrder: null,
            isOrderUrgent: false,
        },
        deliveryTypes: [
            {key: 1, enumName: 'DeliveryToTheOffice', name: 'Доставка в офис ЛЛК-Интернешнл'},
            {key: 2, enumName: 'DeliveryToMoscow', name: 'Доставка по Москве'},
            {key: 3, enumName: 'DeliveryToOtherRegion', name: 'Доставка в другой регион'},
        ],
        userEmail: '',
        userPhoneNumber: ''
    },
    getters: {
        isCartLoaded: ({isCartLoaded}) => isCartLoaded,
        isCartUpdating: ({isCartUpdating}) => isCartUpdating,
        isProductOrderCreating: ({isProductOrderCreating}) => isProductOrderCreating,
        areSameAgenciesProducts: ({areSameAgenciesProducts}) => areSameAgenciesProducts,
        deliveryTypes: ({deliveryTypes}) => deliveryTypes,
        deliveryInfo: ({deliveryInfo}) => deliveryInfo,
        deliveryType: ({deliveryInfo: {type}}) => type,
        deliveryRegion: ({deliveryInfo: {region}}) => region,
        deliveryCity: ({deliveryInfo: {city}}) => city,
        deliveryStreet: ({deliveryInfo: {street}}) => street,
        deliveryHouse: ({deliveryInfo: {house}}) => house,
        deliveryAddress: ({deliveryInfo: {address}}) => address,
        deliveryRecipientName: ({deliveryInfo: {recipientName}}) => recipientName,
        deliveryRecipientEmail: ({deliveryInfo: {recipientEmail}}) => recipientEmail,
        deliveryIsOrderUrgent: ({deliveryInfo: {isOrderUrgent}}) => isOrderUrgent,
        deliveryContactNumber: ({deliveryInfo: {contactNumber}}) => contactNumber,
        splitOrder: ({deliveryInfo: {splitOrder}}) => splitOrder,
        deliveryOptionalContactNumber: ({deliveryInfo: {optionalContactNumber}}) => optionalContactNumber,
        deliveryOrderPurpose: ({deliveryInfo: {orderPurpose}}) => orderPurpose,
        deliveryOrderOrganization: ({deliveryInfo: {orderOrganization}}) => orderOrganization,
        deliveryComment: ({deliveryInfo: {comment}}) => comment,
        isSelectedDeliveryToOtherRegion({deliveryInfo: {type}}) {
            return type === DeliveryToOtherRegion;
        },
        isSelectedDeliveryToTheOffice({deliveryInfo: {type}}) {
            return type === DeliveryToTheOffice;
        },
        isSelectedDeliveryToTheOrganization({deliveryInfo: {type}}) {
            return type === DeliveryToTheOffice
        },
        isSelectedDeliveryToMoscow({deliveryInfo: {type}}) {
            return type === DeliveryToMoscow;
        },
        anyProductBalanceIsNotEnough(_, __, rootGetters) {
            return rootGetters['cart/items'] && rootGetters['cart/items'].some(l => l.quantity > l.structuralSubdivisionProductBalance);
        },
        userEmail: ({userEmail}) => userEmail,
        userPhoneNumber: ({userPhoneNumber}) => userPhoneNumber,
        canCreateOrder: ({isProductOrderCreating}, {anyProductBalanceIsNotEnough}) => !anyProductBalanceIsNotEnough && !isProductOrderCreating
    },
    mutations: {
        SET_CART_LOADED(state, loaded) {
            state.isCartLoaded = loaded;
        },
        SET_CART_UPDATING(state, updating) {
            state.isCartUpdating = updating
        },
        SET_PRODUCT_ORDER_CREATING(state, created) {
            state.isProductOrderCreating = created;
        },
        SET_ARE_SAME_AGENCIES_PRODUCTS(state, areSameAgenciesProducts) {
            state.areSameAgenciesProducts = areSameAgenciesProducts;
        },
        SET_DELIVERY_INFO(state, payload) {
            Object.assign(state.deliveryInfo, {...state.deliveryInfo, ...payload});
        },
        SET_USER_EMAIL(state, email) {
            state.userEmail = email;
        },
        SET_USER_PHONE_NUMBER(state, phoneNumber) {
            state.userPhoneNumber = phoneNumber.replace(/\D/g, '');
        },
        SET_DELIVERY_ADDRESS(state, address ) {
            state.deliveryInfo.address = address
        }
    },
    actions: {
        async getCartInfo({commit, dispatch}) {
            setLoading();
            const {
                data: {
                    data: {
                        areSameAgenciesProducts
                    }
                }
            } = await dispatch('cart/fetchCartInfo', '', {root: true});
            
            commit('SET_ARE_SAME_AGENCIES_PRODUCTS', areSameAgenciesProducts);
            commit('SET_CART_LOADED', true);
            removeLoading();
        },
        async getUserInfo({commit}) {
            setLoading();

            try {
                const {
                    data: {
                        status, data: {
                            email,
                            phone_number
                        }
                    }
                } = await api.cart.getSubdivisionUserInfo();

                if (status === 'ok') {
                    commit('SET_USER_EMAIL', email);
                    commit('SET_USER_PHONE_NUMBER', phone_number);
                }
            } catch (e) {
                removeLoading();
            }
        },
        async addProductUpdateLine(
          {commit, dispatch, getters: {isCartUpdating}},
          {productId, sizeId = null, quantity = 1, balance = 1}
        ) {
            if (isCartUpdating) return false;
            if ((quantity + 1) > balance) return false;
          
            commit('SET_CART_UPDATING', true);
            if (await dispatch('cart/addProduct', {productId, sizeId}, {root: true})) {
                await dispatch('getCartInfo');
            }
            commit('SET_CART_UPDATING', false);
        },
        async removeProductUpdateLine(
            {commit, dispatch, getters: {isCartUpdating}},
            { productId, sizeId = null, quantity = 1, completely = false },
        ) {
            if (isCartUpdating) {
                return false;
            }
          
            if (quantity === 1 || completely) {
                const {isConfirmed} = await showConfirm('Подтвердите действие', 'Вы уверены, что хотите удалить данный товар из корзины?');
                
                if (!isConfirmed) return;
            }
          
            commit('SET_CART_UPDATING', true);
            if (await dispatch('cart/removeProduct', {productId, sizeId, quantity: 1, completely: completely}, {root: true})) {
                await dispatch('getCartInfo');
            }
            commit('SET_CART_UPDATING', false);
        },
        async changeQuantity({dispatch, commit}, {
            productId,
            sizeId = null,
            newQuantity,
            currentQuantity,
            balance = 1,
        }) {
            if (isNaN(newQuantity)) return;

            let minmaxQuantity = Number(newQuantity)
            
            if (minmaxQuantity >= balance)
                minmaxQuantity = balance;
            if (minmaxQuantity <= 0)
                minmaxQuantity = 1;

            const difference = Math.abs(currentQuantity - minmaxQuantity);

            if (minmaxQuantity > currentQuantity && minmaxQuantity <= balance) {
                commit('SET_CART_UPDATING', true);
                if (await dispatch('cart/addProduct', {productId, sizeId, quantity: difference}, {root: true})) {
                    await dispatch('getCartInfo');
                }
            } else if (minmaxQuantity < currentQuantity) {
                commit('SET_CART_UPDATING', true);
                if (await dispatch('cart/removeProduct', {productId, sizeId, quantity: difference, completely: currentQuantity === 1}, {root: true})) {
                    await dispatch('getCartInfo');
                }
            }
            commit('SET_CART_UPDATING', false);
        },
        selectDeliveryType({commit, getters}, type) {
            commit('SET_DELIVERY_INFO', { type });

            const {isSelectedDeliveryToOtherRegion, isSelectedDeliveryToTheOffice, isSelectedDeliveryToTheOrganization} = getters;

            commit('SET_DELIVERY_INFO', {
                city: !isSelectedDeliveryToOtherRegion ? "Москва" : null,
                address: isSelectedDeliveryToTheOffice ? "Садовническая улица, 75" : '',
                street: isSelectedDeliveryToTheOffice ? "Садовническая улица" : null,
                house: isSelectedDeliveryToTheOffice ? "75" : null,
                orderOrganization: isSelectedDeliveryToTheOrganization ? 'ООО «ЛЛК-Интернешнл»' : null,
                region: !isSelectedDeliveryToOtherRegion ? '' : null

            });
        },
        async createOrder({commit, dispatch, getters: {deliveryInfo}}) {
            setLoading();

            let success = false;
            commit('SET_PRODUCT_ORDER_CREATING', true);

            try {
                const {data: {status, message}} = await api.cart.createOrder(deliveryInfo);

                if (status === 'ok') {
                    success = true;
                    await Promise.all([dispatch('getCartInfo'), dispatch('account/getUserInfo', '', {root: true})]);
                }

                showMessage(status, message);
            } catch (e) {
                const {status, message} = e.response.data

                if (!success) {
                    await Promise.all([dispatch('getCartInfo'), dispatch('account/getUserInfo', '', {root: true})]);
                }

                showMessage(status, message);
            } finally {
                commit('SET_PRODUCT_ORDER_CREATING', false);
                removeLoading();
            }

            if (success) {
                router.push({name: 'SuccessfulOrder'});
                commit('SET_DELIVERY_INFO', {
                    type: null,
                    region: null,
                    city: null,
                    address: null,
                    street: null,
                    house: null,
                    recipientName: null,
                    recipientEmail: null,
                    contactNumber: null,
                    optionalContactNumber: null,
                    orderPurpose: null,
                    orderOrganization: null,
                    comment: null,
                    isOrderUrgent: false
                });
            }
        },
    }
}
