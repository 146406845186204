import api from '@/api';
import {showMessage} from "@/lib/toasted";
import {removeLoading, setLoading} from "@/lib/loading";
import router from "@/router";
import {showConfirm} from "@/lib/swal2";

export default {
    namespaced: true,
    state: {
        isCartLoaded: false,
        isCartUpdating: false,
        isProductOrderCreating: false,
        dealerBalance: 0,
        dealerHasContract: null,
        areSameAgenciesProducts: null,
        deliveryAddresses: [],
        selectedAddress: null,
        splitOrder: null,
        comment: null,
        deliveryEmail: '',
        deliveryPhone: '',
        dealerStocksSale: null,
    },
    getters: {
        dealerStocksSale: ({ dealerStocksSale }) => dealerStocksSale,
        dealerBalance: ({dealerBalance}) => dealerBalance,
        isCartLoaded: ({isCartLoaded}) => isCartLoaded,
        isCartUpdating: ({isCartUpdating}) => isCartUpdating,
        isProductOrderCreating: ({isProductOrderCreating}) => isProductOrderCreating,
        hasContract: ({dealerHasContract}) => dealerHasContract,
        areSameAgenciesProducts: ({areSameAgenciesProducts}) => areSameAgenciesProducts,
        deliveryAddresses: ({deliveryAddresses}) => deliveryAddresses,
        selectedAddress: ({selectedAddress}) => selectedAddress,
        comment: ({comment}) => comment,
        isEnoughBalance: (_, getters, __, rootGetters) => {
          console.log(rootGetters['cart/getTotal'])
            return rootGetters['cart/getTotal'] <= getters.dealerBalance;
        },
        deliveryEmail: ({deliveryEmail}) => deliveryEmail,
        deliveryPhone: ({deliveryPhone}) => deliveryPhone,
        splitOrder: ({ splitOrder }) => splitOrder,
    },
    mutations: {
        SET_CART_LOADED(state, loaded) {
            state.isCartLoaded = loaded;
        },
        SET_CART_UPDATING(state, updating) {
            state.isCartUpdating = updating
        },
        SET_PRODUCT_ORDER_CREATING(state, created) {
            state.isProductOrderCreating = created;
        },
        SET_DEALER_BALANCE(state, balance) {
            state.dealerBalance = balance;
        },
        SET_DEALER_HAS_CONTRACT(state, hasContract) {
            state.dealerHasContract = hasContract
        },
        SET_ARE_SAME_AGENCIES_PRODUCTS(state, areSameAgenciesProducts) {
            state.areSameAgenciesProducts = areSameAgenciesProducts;
        },
        SET_DELIVERY_ADDRESSES(state, addresses) {
            state.deliveryAddresses = addresses;
        },
        SET_SELECTED_ADDRESS(state, address) {
            state.selectedAddress = address;
        },
        SET_COMMENT(state, comment) {
            state.comment = comment;
        },
        SET_DELIVERY_EMAIL(state, email) {
            state.deliveryEmail = email;
        },
        SET_DELIVERY_PHONE(state, phone) {
            state.deliveryPhone = phone;
        },
        SET_DEALER_STOCK_SALE(state, dealerStocksSale) {
            state.dealerStocksSale = dealerStocksSale;
        },
        SET_SPLIT_ORDER(state, isSplitOrder) {
            state.splitOrder = isSplitOrder
        },
    },
    actions: {
        async getCartInfo({commit, dispatch}) {
            setLoading();
            const {
              data: {
                data: {
                  dealerBalance,
                  dealerHasContract,
                  areSameAgenciesProducts,
                  dealerStocksSale,
                }
              }
            } = await dispatch('cart/fetchCartInfo', '', {root: true});

            commit('SET_DEALER_STOCK_SALE', dealerStocksSale)
            commit('SET_DEALER_BALANCE', dealerBalance);
            commit('SET_DEALER_HAS_CONTRACT', dealerHasContract);
            commit('SET_ARE_SAME_AGENCIES_PRODUCTS', areSameAgenciesProducts);
            commit('SET_CART_LOADED', true);
            removeLoading();
        },
        async getDeliveryAddresses({commit}) {
            setLoading();

            try {
                const {data: {status, data: {addresses}}} = await api.cart.getDeliveryAddresses();
                if (status == 'ok') {
                    commit('SET_DELIVERY_ADDRESSES', addresses);
                }
                if (addresses && addresses.length > 0) {
                    commit('SET_SELECTED_ADDRESS', addresses[0].id)
                }
            } catch (e) {
                return false;
            } finally {
              removeLoading();
            }
        },
        async addProductUpdateLine(
          {commit, dispatch, getters: {isCartUpdating}},
          {productId, sizeId = null},
        ) {
            if (isCartUpdating) return false;

            commit('SET_CART_UPDATING', true);
            if (await dispatch('cart/addProduct', {productId, size: sizeId}, {root: true})) {

                await dispatch('getCartInfo');
            }
            commit('SET_CART_UPDATING', false);
        },
        async removeProductUpdateLine(
          {commit, dispatch, getters: {isCartUpdating}},
          {productId, sizeId = null, quantity = 1, completely = false},
        ) {
            if (isCartUpdating) return false;

            if (quantity === 1) {
                const {isConfirmed} = await showConfirm('Подтвердите действие', 'Вы уверены, что хотите удалить данный товар из корзины?');

                if (!isConfirmed) return;
            }

            commit('SET_CART_UPDATING', true);
            if (await dispatch('cart/removeProduct', {productId, sizeId, quantity: 1, completely}, { root: true })) {

                await dispatch('getCartInfo');
            }
            commit('SET_CART_UPDATING', false);
        },
        async createOrder({commit, dispatch, getters: {selectedAddress, comment, deliveryEmail, deliveryPhone, splitOrder}}) {
            setLoading();

            let success = false;

            try {
                commit('SET_PRODUCT_ORDER_CREATING', true);

                const {data: {status, message}} = await api.cart.createOrder({
                    addressId: selectedAddress,
                    recipient_email: deliveryEmail,
                    recipient_phone: deliveryPhone,
                    splitOrder,
                    comment
                });

                if (status === 'ok') {
                    success = true;
                    await Promise.all([dispatch('getCartInfo'), dispatch('account/getUserInfo', '', {root: true})]);
                }

                showMessage(status, message);
            } catch (e) {
                const {status, message} = e.response.data

                if (!success) {
                    await Promise.all([dispatch('getCartInfo'), dispatch('account/getUserInfo', '', {root: true})]);
                }

                showMessage(status, message);
            } finally {
                commit('SET_PRODUCT_ORDER_CREATING', false);
                removeLoading();
            }

            if (success) {
                router.push({name: 'SuccessfulOrder'});
                commit('SET_DELIVERY_EMAIL', '')
                commit('SET_DELIVERY_PHONE', '')
            }
        }
    }
}
