import {get} from "@/lib/axios";

export default {
    async getCloseOrdersDealerByDate() {
        return get('/close-orders-dealer-by-date');
    },

    async showLimitedSeries() {
        return get('/show-limited-series');
    }
}
