import api from '@/api';

export default {
    namespaced: true,
    state: {
        closeOrdersDealerByDate: '',
    },
    getters: {
        closeOrdersDealerByDate: ({closeOrdersDealerByDate}) => closeOrdersDealerByDate,
    },
    mutations: {
        SET_CLOSE_ORDERS_DEALER_BY_DATE(state, closeOrdersDealerByDate) {
            state.closeOrdersDealerByDate = closeOrdersDealerByDate;
        },
    },
    actions: {
        async fetchCloseOrdersDealerByDate({commit}) {
            try {
                const isLimited = await api.builtInParameter.showLimitedSeries();
                const items = await api.builtInParameter.getCloseOrdersDealerByDate();
                if (!isLimited.data.data) {
                    commit('SET_CLOSE_ORDERS_DEALER_BY_DATE', items.data.data);
                }
            } catch (e) {
                console.warn(e);
            }
        },
    }
}
