import router from "@/router";
import dealer from "./Cart_dealer";
import subdivision from "./Cart_subdivision"
import { removeLoading, setLoading } from "@/lib/loading";
import { showMessage } from "@/lib/toasted";
import api from '@/api';


export default {
    namespaced: true,
    modules: {
        dealer,
        subdivision
    },
    state: {
        itemsInCart: 0,
        inCartProductIds: [],
        items: [],
    },
    getters: {
        items: ({items}) => items.filter((item) => item.isLongTermProduction !== 1),
        itemsLongTermProduction: ({items}) => items.filter((item) => item.isLongTermProduction === 1),
        itemsInCart: ({itemsInCart}) => itemsInCart,
        getTotal: ({items}) => items.reduce((acc, item) => acc + ((item.price || 1) * item.quantity), 0),
        inCartProductIds: ({inCartProductIds}) => inCartProductIds,
        isProductInCart: (state, getters) => (productId) => {
            return getters.inCartProductIds.indexOf(productId ? productId : null) >= 0
        },
    },
    mutations: {
        SET_ITEMS(state, items) {
            state.items = items;
        },
        SET_ITEMS_IN_CART(state, quantity) {
            state.itemsInCart = quantity
        },
        SET_IN_CART_PRODUCT_IDS(state, productIds) {
            state.inCartProductIds = productIds
        }
    },
    actions: {
        goToCart({rootGetters}) {
            if(rootGetters['account/isCurrentUserDealer']) {
                router.push({name: 'DealerCart'});
            } else if (rootGetters['account/isCurrentUserStructuralSubdivisionUser']) {
                router.push({name: 'SubdivisionUserCart'});
            }
        },
        async fetchCartInfo({commit}) {
            try {
                const cartResponse = await api.cart.getCart();
                let itemsInCart = 0;

                cartResponse.data.data.cartLines.forEach((line) => {
                  itemsInCart += line.quantity;
                });
                commit('SET_ITEMS_IN_CART', itemsInCart);
                commit('SET_ITEMS', cartResponse.data.data.cartLines);
                return cartResponse;
            } catch (e) {
                console.log('e', e);
            }
        },
        async addProduct({dispatch}, {productId, quantity = 1, size= null}) {
            setLoading();

            try {
                const {data: {status, message}} = await api.cart.addItem(productId, quantity, size);

                await dispatch('account/getUserInfo', '', { root: true })

                showMessage(status, message);

                return status === 'ok';
            } catch (e) {
                console.log('e', e);
                return false;
            } finally {
              removeLoading();
            }
        },
        async removeProduct({dispatch}, {productId, quantity= 1, completely = false, sizeId = null}) {
            setLoading();

            try {
                const {data: {status, message}} = await api.cart.removeItem(productId, quantity, completely, sizeId);

                await dispatch('account/getUserInfo', '', { root: true })
                showMessage(status, message);

                return status === 'ok';
            } catch (e) {
                console.log('e', e);
                return false;
            } finally {
                removeLoading();
            }
        },
    }
}
